import React from 'react'
import axios from 'axios';
import {apiConfig} from '../Classes/Helper.jsx';
import './AgentForm.scss';
import $ from 'jquery';

class AgentForm extends React.Component {

    constructor() {
        super()
        this.state = {
            success: false,
            divisions: [],
        }
    }
    capitalizeFirstLetter(string) {
        if(string.indexOf(' ')>=0){
          var str = string.split(' ');
          for(var i = 0;i<str.length; i++){
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1).toLowerCase();
          }
          return str.join(' '); 
        }else{
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        
    }
    submitForm()
    {				  
              var formData = $('#inputform').serialize();
            //   console.log(formData);
                $('.divLoading').show();
                //$("html, body").stop().animate({scrollTop:0}, '500', 'swing', function() {});

            var data = {
                action: 'postagentsalesforceform',
                data: formData
                };
                // console.log(data);
        
                $.ajax({
                    type: 'POST',
                    data: data,
                    url: window.location.origin+"/wp-admin/admin-ajax.php",
                    success: function(response) {
                        response=response.replace('"',"");
                        response=response.replace('"',"");
                        var res = response.match(/Agent Updated/gi);
                        var res2 = response.match(/Agent Created/gi);
                        if(res || res2)
                        {
                            $("#inputform").hide();
                            $(".errorDiv").html();
                            $('.divSuccess').show();
                            window.dataLayer.push({'event':'brokerRegistration'});
                        }
                        else
                        {
                            $('.divSuccess').hide();
                            $(".errorDiv").html(response.replace("1",""));
                        }
                        $('.divLoading').hide();
                    },
                    error:function(response){
                      $('.divLoading').hide();
                      $('.divSuccess').hide();
                      $(".errorDiv").html(response.replace("1",""));
                      
                    }
                });
                
    }
    checkemail() {
        var str = $("#email").val();
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (filter.test(str)) {
            return true;
        } else {
            
            return false;
        }

    }

    checkphone() {
        var inputtxt = $("#phone").val();
        var phoneno = /^\d{10}$/;
        if (inputtxt.match(phoneno)) {
            return true;
        } else {
             
            return false;
        }
    }
    componentDidMount() {

        axios.get(apiConfig().API_URL+'/broker_page')
        .then(response => {
          this.setState({
            divisions : response.data.data,
            success: response.data.success
          });
        //   console.log(response);
        })
   
            window.CallTrk.swap();
            window.JQUERY("#inputform").parsley();  
    }
    componentDidUpdate(){

        window.JQUERY("#inputform").parsley();  

        var that = this;

        var fields_arr = ['firstname','lastName','Title'];

        $(fields_arr).each(function(index, element) {
            $('#' + element).blur(function(){ 
                            this.value = that.capitalizeFirstLetter(this.value);
            });
            });

        var check = function(event) {
                var phone = $(this).val();
                phone = phone.replace(/\D/g,"");
                if(phone.length>=9) {
                    phone = "("+ phone.slice(0,3)+") "+phone.slice(3,6)+"-"+phone.slice(6);
                }
                $(this).val(phone);
        };

        $('#Phone').on("blur", check);

       $("#inputform").on('submit',function(e) {
            if($('.parsley-errors-list ').hasClass('filled') === true){
                return false;  
            }
            else{
                e.preventDefault();
                that.submitForm();   
                return false; 
            }
       });

       $("#email").on('keypress',function () {
        if ($.trim($(this).val()).length==0) {
            $(".errorDiv").html("");
            $("#contact-error").html("");
            // console.log("email key press")
        }

    });

    $("#phone").on('change',function () {
        if ($.trim($(this).val()).length==0) {
            $(".errorDiv").html("");
            $('#myfield').rules('remove', 'required');
            // console.log("phone key press")
        }

    });

        window.CallTrk.swap();
    }

    render() {
        const divisions = this.state.divisions;
        var broker_form = this.props.broker_form;
        return (
            <div>
                    <section className="agent_section">
                        <div className="wrap wrap--lifestyle-about wrap--reward">
                            <div className="agent_form_section">
                                {broker_form.interest_headline ? (
                                    <h2 tabindex="0" className="agent_form_heading">{broker_form.interest_headline}</h2>
                                ):('')}
                                {broker_form.interest_subtext ? (
                                      <p tabindex="0" className="agent_form_description">{broker_form.interest_subtext}</p>
                                ):('')}
                                    <div tabindex="0" className="divSuccess" style={{display:'none'}}><p>Thank you for registering.</p></div>
                                    <span tabindex="0" className="divLoading login-status login-error" style={{display:'none'}}>loading...</span>
                                    <form id="inputform"  class="parsley-validate agent_form"  method="post">                              
                                        <div class="errorDiv"></div>
                                        <input id="firstname" maxlength="80" name="firstname" required data-parsley-error-message="Please enter a first name" size="20" type="text" placeholder="First Name (required)" aria-label="First Name"/>
                                        <input id="lastName" maxlength="80" name="lastname"  required data-parsley-error-message="Please enter a last name" size="20" type="text" placeholder="Last Name (required)" aria-label="Last Name"/>
                                        <input id="email"  type="email"  maxlength="80" name="email" required data-parsley-error-message="Please enter a valid email" placeholder="Email (required)" aria-label="Email"/>
                                        <input  id="Phone" maxlength="14" name="phone" type="text" required data-parsley-error-message="Please enter a valid phone no" placeholder="Phone (required)" aria-label="Phone"/>
                                        <input  id="licenseNo" maxlength="80" name="licenseno" type="text" required data-parsley-error-message="Please enter a valid License Number" placeholder="License (required)" aria-label="Licence"/>   
                                        <select id="division" name="division" required data-parsley-error-message="Please select a valid division" aria-label="Division">
                                            <option value="">Select Division (required)</option>
                                            {divisions.map((item) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </select>   
                                        <input type='hidden' name="brand" value="TRI Pointe Homes"/>                        
                                        <button id="submitbutton" class="btn btn--medium-blue agent_submit" role="button" type="submit">Submit </button>
                                    </form> 
                                <p tabindex="0" className="agent_form_disclaimer">By providing your name and contact information and clicking the submission button, you consent and agree to receive marketing communications from us or made on our behalf, including emails and calls or text messages using an automatic telephone dialing system or an artificial or prerecorded voice. Your consent and agreement to the foregoing, including the receipt of autodialed marketing messages, is not a condition of purchasing any property, goods, or services from us, and you may call us instead of submitting your information online. Your consent herein also applies to any future registration on national or state Do Not Call lists. For mobile phones, standard text message and data charges apply. Consult our <a href="/privacy-policy/" target="_blank">Privacy Policy</a> for additional information, including unsubscribe options.</p> 
                            </div>
                        </div>    
                    </section> 
            </div>
        )
    }
}

export default AgentForm
