import React from 'react';
import axios from 'axios';
import GlobalField from '../../Classes/GlobalField';
import {apiConfig} from '../../Classes/Helper.jsx';
// import FaqSection from '../../Classes/FaqSection';
import AgentForm from '../../Classes/AgentForm';
import SectionTitle from '../../Classes/SectionTitle';
import NoMatch from './NoMatch';
import { Modal } from 'react-bootstrap';
import SkeletonComp from '../../Classes/SkeletonComp.jsx';
import { Redirect } from "react-router-dom";

class BrokersPage extends React.Component {  

  constructor(props) {
      super(props);
      this.state ={
        static_component : [],
        success : false,
      }
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);      
  }

  handleClose() {
      this.setState({ show: null });
  }

  handleShow(id) {
      this.setState({ show: id });
  }

  fetch_data(){
    axios.get(apiConfig().API_URL+'/static_info/brokers')
    .then(response => {
      this.setState({      
        static_component : response.data.data,
        success: response.data.success
      })
      // console.log(response);
    })
  }

  componentDidMount(){
    this.fetch_data();
    window.CallTrk.swap();
  }

  componentDidUpdate(){
    window.CallTrk.swap();
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.location.pathname != nextProps.location.pathname){
      this.props = nextProps;
      this.setState({
        success: false
      })
      this.fetch_data();
    }
  } 

  render() {    
    var success = this.state.success;
    var message = this.state.message; 
    var static_component = this.state.static_component;
    var component = static_component.component;
    for(var x in component ){
      if(component[x].acf_fc_layout == "global_faq_section")
       {component.push( component.splice(x,1)[0] )}
      
      }
     return (  
      <>  
          {success === true ? [ 
            <div role="main" className="global-main"> 
                <GlobalField global_component={component} community_details={static_component}/>
                {/* <div className="agent_button">
                    <SectionTitle 
                        subtext = ""
                        title_part_1="Would you like to "    
                        title_part_2="stay informed online?"  
                        headline_part_1_color = "#bed245"  
                        headline_part_2_color = "#545454" 
                    />
                    <a href="javascript:void(0)" className="filled-btn" title="Click Here" onClick={() => this.handleShow("Broker_modal")}>Click Here</a>
                </div> */}
                {/* <Modal className="Estimate_modal Request_quote" show={this.state.show === "Broker_modal"} onHide={this.handleClose} >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <AgentForm/>
                    </Modal.Body>
                </Modal> */}
                {/* <FaqSection community_details={static_component}/> */}
                {/* {static_component.static_content && <div className="staic-content" dangerouslySetInnerHTML={{__html:static_component.static_content.replace(/(?:\r\n|\r|\n)/g, '')}}></div>}
                <div className="static_button_content">
                  {static_component.static_button_content.length > 0 ? (
                    static_component.static_button_content.map(items => (
                      items.button_link && items.static_button ? (
                        <a href={items.button_link}  style={{backgroundColor:items.button_color }} className="learn-more" title={items.static_button}>{items.static_button}</a>
                      ):('')
                    )
                  )):('')}
                </div> */}
            </div>
          ]:[ message != 'Not Completed' ?   <SkeletonComp/>  :  <Redirect to={<NoMatch/>} />]}
        
      </>
     );  
  }  
}  

export default BrokersPage; 