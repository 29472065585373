export function apiConfig(){

    return {
         API_URL : process.env.Api_Path
     }
 
 }

 

