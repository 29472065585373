import axios from 'axios';
import React from 'react';
import SubNavbar from '../../Classes/SubNavbar';
import UtilityNav from '../../Classes/UtilityNav';
import Direction from '../../Classes/Direction';
import DirectionMapBlock from '../../Classes/DirectionMapBlock';
import { Redirect } from "react-router-dom";
import {apiConfig} from '../../Classes/Helper.jsx';
import NoMatch from './NoMatch';
import NhsDesktop from '../../Classes/NhsDesktop';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {Helmet} from "react-helmet";

class DirectionPage extends React.Component {
	
	state ={
		direction_component : '',
		success : false,
	}
	/*Local Business*/
	local_business_structured_data(direction_details){
		if(direction_details) {

		if(direction_details.agent_info.agents_contact_phone){
			var telephone = direction_details.agent_info.agents_contact_phone;
		}
		if(direction_details.display_price){
					var priceRange = direction_details.display_price;
		}
		if(direction_details.physical_address.physical_address_heading){
			var addressLocality = direction_details.physical_address.physical_address_heading.replace( /(<([^>]+)>)/ig, '');
		}
		if(direction_details.streetAddress){
			var streetAddress = direction_details.streetAddress;
		}
		if(direction_details.postalCode){
			var postalCode = direction_details.postalCode;
		}
		if(direction_details.latitude){
			var latitude = direction_details.latitude;
		}
		if(direction_details.longitude){
			var longitude = direction_details.longitude;
		}
		if(direction_details.state_abb){
			var state_abb = direction_details.state_abb.toUpperCase();
		}
		if(direction_details.site_logo){
			var site_logo = direction_details.site_logo;
		}
		}
		const script = document.createElement("script");
		// Structure Data script start 
		script.type = "application/ld+json";
		script.innerHTML = `{
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"name": "Tri Pointe Homes",
		"image": "${site_logo}",
		"@id": "",
		"url": "${window.location.href}",
		"telephone": "${telephone}",
		"priceRange": "${priceRange}",
		"address": {
		"@type": "PostalAddress",
		"streetAddress": "${streetAddress}",
		"addressLocality": "${addressLocality}",
		"addressRegion": "${state_abb}",
		"postalCode": "${postalCode}",
		"addressCountry": "US"
		},
		"geo": {
		"@type": "GeoCoordinates",
		"latitude": "${latitude}",
		"longitude": "${longitude}"
		}  
		}`
		//  Structure Data script end 
		document.head.appendChild(script);

	}
	fetch_data(){
		axios.get(apiConfig().API_URL+'/common_info/'+ this.props.match.params.slug+'/direction')
		.then(response => {
			
			this.setState({
				direction_component : response.data.data,
				success: response.data.success,
			  })
			// console.log(response);
		})
	}

	componentDidMount(){
	this.fetch_data();
	window.CallTrk.swap();
	window.JQUERY('.top-scroll').addClass('dynamic');
	}

	componentDidUpdate(){
	window.CallTrk.swap();
	}
	
	render() {     
		var window_width = window.screen.width;
		var success = this.state.success; 
		var message = this.state.message; 
		var direction_component = this.state.direction_component;
		var direction_details = direction_component.direction_details;
		var posttype = this.state.direction_component.post_type;
		var state_name = this.props.match.params.statename.toLowerCase();
		var submarket_name = this.props.match.params.submarket.toLowerCase();
	
		if(direction_details){
			var comm_status=direction_details.community_status ;
			var submarket_slug=direction_details.submarket_slug;
		// console.log(comm_status)
		// console.log(submarket_slug)
		}
		var yoast_following = direction_component.yoast_following;
		var yoast_indexing = direction_component.yoast_indexing;
		var context_yoast = '';
		if (yoast_indexing === 'true' && yoast_following === 'true') {
		var context_yoast = 'index, follow';
		}else if (yoast_indexing === 'true' && yoast_following === 'false') {
		var context_yoast = 'index, nofollow';
		}else if (yoast_indexing === 'false' && yoast_following === 'true') {
		var context_yoast = 'noindex, follow';
		}else if (yoast_indexing === 'false' && yoast_following === 'false') {
		var context_yoast = 'noindex, nofollow';
		}
		const meta = {
			title: direction_component.yoast_title,
			description: direction_component.yoast_desc,
			canonical: window.location.href
    	};
		if(direction_details){
			this.local_business_structured_data(direction_details);
			
		  }
		return (
			<>  
		   <Helmet>
                <title>{ direction_component.yoast_title}</title>
                <meta name="description" content={meta.description} />
                <meta property="og:title" content={ direction_component.yoast_title} />
                <meta property="og:description" content={meta.description} /> 
				<meta property="og:image" content={direction_component.yoast_image}/>
				<meta name="robots" content={context_yoast}  /> 
              	<link rel="canonical" href={meta.canonical} />
				<meta property="og:url" content={meta.canonical} />
				<meta property="og:type" content='Website' />
            </Helmet>
			{success === true ? [
				comm_status ?
				comm_status !=="Archive"?(
				state_name === direction_details.state_abb.toLowerCase() && submarket_name === direction_details.submarket.toLowerCase()  ? (
					direction_details.show_direction === true ? [
						<div role="main"  className="direction-main">  
							<SubNavbar navbar_detail={direction_component} community_details={direction_details} posttype={posttype} direction_breadcrumb ='direction_breadcrumb'/>
							<Direction  direction_details={direction_details} posttype={posttype}/>
							<DirectionMapBlock direction_details={direction_details}/>
							{(window_width < 767) ? (
								<UtilityNav sfdc_chat={direction_details.sfdc_chat_details} agent_info={direction_details.agent_info} community_component={direction_details} posttype={posttype}/>
							):(
								<NhsDesktop sfdc_chat={direction_details.sfdc_chat_details} agent_info={direction_details.agent_info} community_component={direction_details} posttype={posttype}/>
							)} 
						</div>  
					]:[ <Redirect to={direction_details.comm_permalink} /> ]
				):(<Redirect to={<NoMatch/>} />)):  (<Redirect to={submarket_slug} />) : (<Redirect to={<NoMatch/>} />)
			]:[ message != 'Not Completed' ?  
			<SkeletonTheme >
				<div className="direction-main">  
					<div className="sub-navbar">
						<nav className="navbar navbar-expand-xl navbar-light">
							<div className="left-nav navbar-nav">
								<Skeleton width={100} className="nav-link"/>
								<Skeleton width={100} className="nav-link"/>
								<Skeleton width={100} className="nav-link"/>
								<Skeleton width={100} className="nav-link"/>
								<Skeleton width={100} className="nav-link"/>
							</div>
						</nav>
						<div className="breadcrumbs_section">
							<Skeleton width={300}/>
						</div>
					</div>
					<div className="direction-main-div">
						<div className="main-header">
							<div className="direction-header">
									<h1 className="direction-heading">
										<Skeleton/>
									</h1>
									<Skeleton height={40} className="direction-subtext"/>
							</div>
						</div>
						<div className="direction-content-image">
							<div className="direction-content">
								<div className="hours-direction">
										<p className="hours-main">
											<Skeleton height={30}/>
											<Skeleton height={100} className="hours"/>
										</p>,
										<p className="hours-main">
											<Skeleton height={30}/>
											<Skeleton height={100} className="hours"/>
										</p>
										<Skeleton height={30} className="neigh-map"/>
								</div>
							</div>
							<div className="direction-image">
								<div className="image-div">
									<Skeleton height={"100%"}/>
								</div>
							</div>
						</div>
					</div>
					<div className="video-main-div direction-map-div"> 
						<div className="video-iframe divide-equal" >  
							<Skeleton height={"100%"}/>
						</div>
						<div className="video-info divide-equal">
							<Skeleton height={20}/>
							<Skeleton height={40}/>
							<Skeleton height={150} className="title-info"/>
							<Skeleton height={30} className="learn-more"/>
						</div>
					</div> 
				</div> 
			</SkeletonTheme>
			:  <Redirect to={<NoMatch/>} />]}
		
			</>
		);  
	}  
	}  

export default DirectionPage; 
