import React from 'react';
import axios from 'axios';
import CommonNeighCard from '../../Classes/CommonNeighCard';
import GlobalField from '../../Classes/GlobalField';
import GeoAssociates from '../../Classes/GeoAssociates'
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import {apiConfig} from '../../Classes/Helper.jsx';
// import FaqSection from '../../Classes/FaqSection';
import SectionTitle from '../../Classes/SectionTitle';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {Helmet} from "react-helmet";


class StatePage extends React.Component {  
    constructor() {
      super();
    }
    state ={
        state_component : [],
        success : false,
        message: ''
      }
      componentDidMount(){
        axios.get(apiConfig().API_URL+'/state_data/'+ this.props.match.params.slug)
        .then(response => {
          this.setState({
            state_component : response.data.data,
            success: response.data.success,
            message: response.data.message
          })
          // console.log("state response",response);
        })

        window.CallTrk.swap();
      }

      componentDidUpdate(){
        window.CallTrk.swap();
      }
  render(){  
 
    var success = this.state.success; 
    var message = this.state.message; 
    var state_component = this.state.state_component;
    var gc_count=state_component.global_components;
    var component = state_component.component;
    for(var x in component ){
      if(component[x].acf_fc_layout == "global_faq_section")
       {component.push( component.splice(x,1)[0] )}
      
      }
      var yoast_following = state_component.yoast_following;
        var yoast_indexing = state_component.yoast_indexing;
        var context_yoast = '';
        if (yoast_indexing === 'true' && yoast_following === 'true') {
        var context_yoast = 'index, follow';
        }else if (yoast_indexing === 'true' && yoast_following === 'false') {
        var context_yoast = 'index, nofollow';
        }else if (yoast_indexing === 'false' && yoast_following === 'true') {
        var context_yoast = 'noindex, follow';
        }else if (yoast_indexing === 'false' && yoast_following === 'false') {
        var context_yoast = 'noindex, nofollow';
        }
    // const meta = {
    //   title: state_component.yoast_title,
    //   description: state_component.yoast_desc,
    //   canonical: window.location.href
    // };
    return(
      <>
      <Helmet>  
              <title>{state_component.yoast_title}</title>
              <meta name="description" content={state_component.yoast_desc} />
              <meta name="robots" content={context_yoast} /> 
              <meta property="og:title" content={state_component.yoast_title ? state_component.yoast_title : ''} />
              <meta property="og:description" content={state_component.yoast_desc ? state_component.yoast_desc : ''} />
              <meta property="og:image" content={state_component.yoast_image}/>
              <link rel="canonical" href={window.location.href} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content='Website' />
            </Helmet> 
        <div role="main" className="cards-section">
            {success === true ? [ 
                //this.props.match.params.slug === state_component.state_details.state_title.toLowerCase() ? [
                  this.props.match.params.slug === state_component.state_details.slug_match.toLowerCase() ? [
                    state_component.community.length > 0 || state_component.neighborhood.length > 0 || state_component.global_components > 0 ? [
                      gc_count > 0  ? [
                        <GlobalField global_component={state_component.component} slice_start={0} slice_end={state_component.cards_position} />
                      ]:[''],
                            
                            state_component.community.length > 0 ? [
                                <SectionTitle headline={state_component.canonical_pages_data.community_headline} subtext={state_component.canonical_pages_data.community_subtext+' ('+state_component.community.length+')'} posttype="state" community="community"/>,
                                <CommonNeighCard card_detail={state_component.community} posttype="community"   state_abb = {state_component.state_details} show_detail='true'/>
                            ]:[''],
            
                            state_component.neighborhood.length > 0 ? [
                                <SectionTitle headline={state_component.canonical_pages_data.neighborhood_headline} subtext={state_component.canonical_pages_data.neighborhood_subtext+' ('+state_component.neighborhood.length+')'} posttype="state" />,
                                <CommonNeighCard card_detail={state_component.neighborhood} posttype="neighborhood"   state_abb = {state_component.state_details} show_detail='true'/>
                            ]:[''],

                            gc_count >=state_component.cards_position ? (
                              <GlobalField global_component={state_component.component} community_details={state_component} slice_start={state_component.cards_position}/>
                              ):(''),
                              <GeoAssociates associated_data={state_component.associated_submarkets} component={state_component} title='Where We Build In' />,
                          // <FaqSection community_details={state_component}/>
                    ]:[ <Redirect to={<NoMatch/>} />  ]
                ]:[ <Redirect to={<NoMatch/>} />  ]
            ]:[ message != 'Not Completed' ? 
            <SkeletonTheme >
              <div className="schedule-tour">
                <div className="two-image">
                    <div className="image-section" style={{backgroundColor:'#eeeeee'}}>
                        <div className="image-building">
                          <Skeleton className="image-building-image"/>
                        </div>
                    </div>
                </div>
                <div className="content-section">
                    <Skeleton height={20} />
                    <Skeleton height={40}/>
                    <Skeleton height={200}/>
                    <Skeleton width='20%' height={35} />
                </div>
              </div>
              <div className="sectiontitle-main-div">
                <div className="sectiontitle-headline gray-color">
                  <Skeleton height={40}/>
                </div>
                <div className="eyebrow-text-div">
                  <Skeleton height={30} width={200} className="sectiontitle-eyebrow_text"/>
                  <div className="eyebrowtext-border"></div>
                </div>
              </div>
              <div className="common-card">
                <div className="card-main-div">
                  <div className="card-imgs">
                    <Skeleton height={"100%"} />
                    <div className="plan-img-info">
                      <div className="card-img-info">
                        <Skeleton height={20} className="card-status"/>
                        <Skeleton height={40} className="card-name"/>
                        <Skeleton height={20} className="card-price"/>
                      </div>
                    </div>
                  </div>
                  <div className="card-info">
                    <ul className="card-detail">
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-main-div">
                  <div className="card-imgs">
                    <Skeleton height={"100%"} />
                    <div className="plan-img-info">
                      <div className="card-img-info">
                        <Skeleton height={20} className="card-status"/>
                        <Skeleton height={40} className="card-name"/>
                        <Skeleton height={20} className="card-price"/>
                      </div>
                    </div>
                  </div>
                  <div className="card-info">
                    <ul className="card-detail">
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="sectiontitle-main-div">
                <div className="sectiontitle-headline gray-color">
                  <Skeleton height={40}/>
                </div>
                <div className="eyebrow-text-div">
                  <Skeleton height={30} width={200} className="sectiontitle-eyebrow_text"/>
                  <div className="eyebrowtext-border"></div>
                </div>
              </div>
              <div className="common-card">
                <div className="card-main-div">
                  <div className="card-imgs">
                    <Skeleton height={"100%"} />
                    <div className="plan-img-info">
                      <div className="card-img-info">
                        <Skeleton height={20} className="card-status"/>
                        <Skeleton height={40} className="card-name"/>
                        <Skeleton height={20} className="card-price"/>
                      </div>
                    </div>
                  </div>
                  <div className="card-info">
                    <ul className="card-detail">
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-main-div">
                  <div className="card-imgs">
                    <Skeleton height={"100%"} />
                    <div className="plan-img-info">
                      <div className="card-img-info">
                        <Skeleton height={20} className="card-status"/>
                        <Skeleton height={40} className="card-name"/>
                        <Skeleton height={20} className="card-price"/>
                      </div>
                    </div>
                  </div>
                  <div className="card-info">
                    <ul className="card-detail">
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SkeletonTheme>
            :  <Redirect to={<NoMatch/>} />]}
        </div>
      </> 
    );  
  }  
}  

export default StatePage; 