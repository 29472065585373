import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import '../../Classes/ReviewDetail.scss';
import SubNavbar from '../../Classes/SubNavbar';
import UtilityNav from '../../Classes/UtilityNav';
// import VideoBlock from '../../Classes/VideoBlock';
import {apiConfig} from '../../Classes/Helper.jsx';
import HeadingSection from '../../Classes/HeadingSection.jsx';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import moment from 'moment';
import { Redirect } from "react-router-dom";
import NoMatch from './NoMatch';
import NhsDesktop from '../../Classes/NhsDesktop';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import '../../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
import DocumentMeta from 'react-document-meta';


const options = {
  nav: true,
  dots: false,
  loop: false,
  rewind: true,
  items : 1,
  responsive : {
      0:{
          stagePadding: 30
      },
      568:{
          stagePadding: 80,
          margin : 0
      },
      736:{
          stagePadding: 180
      },
      1024:{
          stagePadding: 250
      },
      1280:{
          stagePadding: 300
      },
      1560:{
        stagePadding: 350
      },
      1680:{
        stagePadding: 450
      }
    }
  }
class ReviewPage extends React.Component {  
  constructor() {
    super();
    this.state = {
      review_component : '',
      success : false,
      review_loaded:true,
      sort:'date'
    };

    this.handleChange = this.handleChange.bind(this);
  }
  /*Review Structured Data*/
  // review_structured_data(review_details){
  //   if(review_details) {
  //       if(review_details.jd_orders.average_rating){
  //           var ratingValue = review_details.jd_orders.average_rating;
  //       }
  //       if(review_details.jd_orders.reviews.length){
  //           var reviewCount = review_details.jd_orders.reviews.length;
  //       }
  //       if(review_details.eliant_data && review_details.eliant_data[0].eliant_text){
  //           var eliant_text = review_details.eliant_data[0].eliant_text;
  //           if(eliant_text.length > 150) {
  //               var description = eliant_text.substring(0,150);
  //               description.replace( /(<([^>]+)>)/ig, '');
  //           }
  //       }
  //       if(review_details.title){
  //           var name = review_details.title;
  //       }
  //       if(review_details.tour_details.tour_post_thumbnail){
  //          var image = review_details.tour_details.tour_post_thumbnail;
  //       }
  //       if(review_details.min_price){
  //          var price = review_details.min_price;
  //       //    var price = price_value.replace(/\D/g, "");
  //       }
  //       var availability='';
  //       if (review_details.community_status=='Coming Soon' || review_details.neigh_status=='Coming Soon') {
  //         availability = '';
  //       } else {
  //          availability = 'https://schema.org/InStock'
  //       }
  //       var review_data = [];
        
  //       if(review_details.jd_orders.reviews.length>0){
  //       for(var i=0;i<review_details.jd_orders.reviews.length;i++){

  //           if(review_details.jd_orders.reviews && review_details.jd_orders.reviews[0].date){
  //               var date = review_details.jd_orders.reviews[0].date;
  //               var datePublished = moment(date).format('MMMM d, YYYY');
  //           }
  //           if(review_details.jd_orders.reviews && review_details.jd_orders.reviews[0].comment){
  //               var comment = review_details.jd_orders.reviews[0].comment;
  //               if(comment.length > 150) {
  //                   var comment_text = comment.substring(0,150);
  //                   comment_text.replace( /(<([^>]+)>)/ig, '');
  //               }
  //           }
  //           if(review_details.jd_orders.reviews && review_details.jd_orders.reviews[0].star_rating){
  //               var ratingValue = review_details.jd_orders.reviews[0].star_rating;
  //           }
  //              var structure_schema = `{
  //                   "@type": "Review",
  //                   "author": "Verified Homeowner",
  //                   "datePublished": "${datePublished}",
  //                   "description": "${comment_text}",
  //                   "name": "Customer Reviews",
  //                   "reviewRating": {
  //                     "@type": "Rating",
  //                     "bestRating": "5",
  //                     "ratingValue": "${ratingValue}",
  //                     "worstRating": "1"
  //                   }
  //                 }`
  //               review_data.push(structure_schema);
  //           }
  //       }
          
       
  //   }
  //   const script = document.createElement("script");
  //   // Structure Data script start 
  //   script.type = "application/ld+json";
  //   script.innerHTML = `{
  //       "@context": "http://schema.org",
  //       "@type": "Product",
  //       "aggregateRating": {
  //         "@type": "AggregateRating",
  //         "ratingValue": "${ratingValue}",
  //         "reviewCount": "${reviewCount}"
  //       },
  //       "description": "${description}",
  //       "name": "${name}",
  //       "image": "${image}",
  //       "offers": {
  //         "@type": "Offer",
  //         "availability": "${availability}",                          
  //         "price": "${price}",
  //         "priceCurrency": "USD"
  //       },
  //       "review": [
  //           ${review_data}
  //       ]
        
  //     }`
  //     //  Structure Data script end 
  //     document.head.appendChild(script);

  // }

  handleChange(event) {
    this.setState({sort: event.target.value,review_component : '',success: false});
    this.fetch_data(event.target.value);
    // console.log("Selected Value"+event.target.value);
  }

  fetch_data(sort = 'date'){
    // console.log("Sort Value"+sort);
    axios.get(apiConfig().API_URL+'/get_reviews/'+this.props.match.params.slug+'/reviews/'+sort)
    .then(response => {
      this.setState({
        review_component : response.data.data,
        success: response.data.success,
        review_loaded: true
      })
      // console.log(response);
    })
	}

	componentDidMount(){
    var perlink = window.location.origin;
     var pathname = window.location.pathname;
     var triming = pathname.split('/reviews');
     var way = triming[0];
     window.location.href = perlink + way +'/?tab=review';

    this.fetch_data();
    window.JQUERY('.top-scroll').addClass('dynamic');
    window.CallTrk.swap();
  }

  starRating = (star_number) => {
    var finalRating = [];
    var rating = star_number;
    var ratingDevide = (rating + "").split('.');
    var isHalfPrint = false;

    for (var i = 1; i<=5; i++){
        if(i <= ratingDevide[0]){ 
            finalRating.push(<span><img tabindex="0" src={require('../../../assets/images/filled.png')} title="star" alt="star"/></span>)
        }else{
            if(ratingDevide[1] !== "" && isHalfPrint === false){
                isHalfPrint = true;
                finalRating.push(<span><img tabindex="0" src={require('../../../assets/images/half-filled.png')} title="star" alt="star"/></span>)
            }else{ 
                finalRating.push(<span><img tabindex="0" src={require('../../../assets/images/unfilled.png')} title="star" alt="star"/></span>)
            }
        }
    }

    return finalRating;
  }

  Small_starRating = (star_number) => {
      var Small_finalRating = [];
      var rating = star_number;
      var ratingDevide = (rating + "").split('.');
      var isHalfPrint = false;
  
      for (var i = 1; i<=5; i++){
          if(i <= ratingDevide[0]){ 
              Small_finalRating.push(<span><img tabindex="0" src={require('../../../assets/images/star-orange-small.png')} title="star" alt="star"/></span>)
          }else{
              if(ratingDevide[1] !== "" && isHalfPrint === false){
                  isHalfPrint = true;
                  Small_finalRating.push(<span><img tabindex="0" src={require('../../../assets/images/half-orange-small.png')} title="star" alt="star"/></span>)
              }else{ 
                  Small_finalRating.push(<span><img tabindex="0" src={require('../../../assets/images/star-gray-small.png')} title="star" alt="star"/></span>)
              }
          }
      }
    return Small_finalRating;
  }

  componentDidUpdate(){
    window.CallTrk.swap();
    }

  render() {     
    var window_width = window.screen.width;
    var review_component = this.state.review_component;
    var review_details = review_component.review_details;
    var success = this.state.success; 
    var message = this.state.message; 
    var state_name = this.props.match.params.statename.toLowerCase();
    var submarket_name = this.props.match.params.submarket.toLowerCase();
    

      const meta = {
        title: review_component.yoast_title,
        description: review_component.yoast_desc,
        canonical: window.location.href
      };
     return ( 
      <>
      <DocumentMeta {...meta} />
      {success === true ? [ 
        state_name === review_details.state_abb.toLowerCase() &&  submarket_name === review_details.submarket.toLowerCase() && review_details.community_status !="Archive" ? (
          review_details.jd_orders !== null && review_details.show_reviews !== false ? (
          review_details.jd_orders.reviews.length > 0  ? [
            <div role="main" className="review-main">  
              <SubNavbar navbar_detail={review_component} community_details={review_details} posttype="neighborhood"/> 
  
              <div className="review-section">
                  <div className="reviews-info">
                      <div className="reviews-heading">
                          <HeadingSection 
                            subtitle={review_details.eliant_data[0].eliant_sub_text}
                            title={review_details.eliant_data[0].eliant_headline}
                            highlight_class='green-color'
                            highlight_dir='left'
                            highlighted_word_count={1}
                            text_class='gray-color'
                            heading_tags='h1_tag'
                          />
                          {review_details.eliant_data[0].eliant_text ? (
                              <p tabindex="0" className="reviews-text" dangerouslySetInnerHTML={{__html:review_details.eliant_data[0].eliant_text.replace(/(?:\r\n|\r|\n)/g, '')}} title={review_details.eliant_data[0].eliant_tooltip_text}></p>
                          ):('')}
                      </div>
                      <div className="reviews-logo">
                          {review_details.eliant_data[0].eliant_logo ? (
                              <div className="eliant_logo">
                                <a href="javascript:void(0)" rel="noopener noreferrer" title="eliant">
                                    <img src={review_details.eliant_data[0].eliant_logo.url} title={review_details.eliant_data[0].eliant_logo.title} alt={review_details.eliant_data[0].eliant_logo.alt ? review_details.eliant_data[0].eliant_logo.alt : review_details.eliant_data[0].eliant_logo.title }/>
                                </a>
                              </div>
                          ):('')}
                        
                          <div className="review-star1">
                          <p tabindex="0" className="star-text">{review_details.jd_orders.average_rating}/5</p>
                              {this.starRating(review_details.jd_orders.average_rating)}
                          </div>
                      </div>
                  </div>
                  <div className="reviews-slider">
                      <div className="slider-top-div">
                          <p tabindex="0" className="total-review">{review_details.jd_orders.reviews.length} Reviews</p>
                          <div className="review-sort">
                              <span tabindex="0">Sort by:</span>
                              <form id="sort_form">
                                  <select tabindex="0" name="sort" value={this.state.sort} onChange={this.handleChange}>
                                      <option tabindex="0" value="date">Most Recent</option>
                                      <option tabindex="0" value="star_rating">Star Rating</option>
                                  </select>
                              </form>
                          </div>
                      </div>
                      {review_details.jd_orders.reviews.length === 1 ?(
                          <div className="single-image">
                              <div className="reviews-slider-div" tabindex="0">
                                  <div tabindex="0" className="review-star1">
                                      {this.Small_starRating(review_details.jd_orders.reviews[0].star_rating)}
                                  </div>
                                  <h5 tabindex="0">Verified Homeowner</h5>
                                  <p tabindex="0" className="silder-text">{review_details.jd_orders.reviews[0].comment}</p>
                                  <p tabindex="0" className="small-gray-text review-date">{review_details.jd_orders.reviews[0].date}</p>
                              </div> 
                          </div>  
                        ):(
                          <OwlCarousel {...options} className="owl-theme">  
                            {review_details.jd_orders.reviews.map(item => (
                              <div className="reviews-slider-div" tabindex="0">
                                  <div tabindex="0" className="review-star1">
                                      {this.Small_starRating(item.star_rating)}
                                  </div>
                                  <h5 tabindex="0" >Verified Homeowner</h5>
                                  <p tabindex="0" className="silder-text">{item.comment}</p>
                                  <p tabindex="0" className="small-gray-text review-date">{moment(item.date).format("LL")}</p>
                              </div>  
                            ))}  
                          </OwlCarousel> 
                      )}
                  </div>
              </div> 

              {(window_width < 767) ? ( 
                  <UtilityNav sfdc_chat={review_details.sfdc_chat_details} agent_info={review_details.agent_info} community_component={review_details} posttype="neighborhood"/>
              ):(
                  <NhsDesktop sfdc_chat={review_details.sfdc_chat_details} agent_info={review_details.agent_info} community_component={review_details} posttype="neighborhood"/>
              )}
            </div> 
          ]:[  <Redirect to={review_details.comm_permalink}/> ]
          ):(<Redirect to={review_details.comm_permalink}/> )
        ):(<Redirect to={<NoMatch/>} />) 
      ]:[ message != 'Not Completed' ?  
        <SkeletonTheme>
            <div className="review-main">
              <div className="sub-navbar">
                <nav className="navbar navbar-expand-xl navbar-light">
                  <div className="left-nav navbar-nav">
                    <Skeleton width={100} className="nav-link"/>
                    <Skeleton width={100} className="nav-link"/>
                    <Skeleton width={100} className="nav-link"/>
                    <Skeleton width={100} className="nav-link"/>
                    <Skeleton width={100} className="nav-link"/>
                  </div>
                </nav>
                <div className="breadcrumbs_section">
                  <Skeleton width={300}/>
                </div>
              </div>
              <div className="review-section">
                <div className="reviews-info">
                  <h1 className="reviews-heading">
                      <Skeleton height={20}/>
                      <Skeleton height={40}/>
                      <Skeleton height={100} className="reviews-text"/>
                  </h1>
                  <div className="reviews-logo">
                      <div className="eliant_logo">
                        <Skeleton height={"100%"}/>
                      </div>
                  </div>
                </div>
              </div>
              <div className="reviews-slider">
                <div className="slider-top-div">
                    <Skeleton height={20}  width={150} className="total-review"/>
                </div>
                <OwlCarousel {...options} className="owl-theme">  
                  <div className="reviews-slider-div" style={{padding:'5%'}}>
                      <div className="review-star1">
                        <Skeleton height={20}/>
                      </div>
                      <Skeleton height={30}/>
                      <Skeleton height={100} className="silder-text"/>
                      <Skeleton height={20}/>
                  </div>  
                  <div className="reviews-slider-div" style={{padding:'5%'}}>
                      <div className="review-star1">
                        <Skeleton height={20}/>
                      </div>
                      <Skeleton height={30}/>
                      <Skeleton height={100} className="silder-text"/>
                      <Skeleton height={20}/>
                  </div>  
                  <div className="reviews-slider-div" style={{padding:'5%'}}>
                      <div className="review-star1">
                        <Skeleton height={20}/>
                      </div>
                      <Skeleton height={30}/>
                      <Skeleton height={100} className="silder-text"/>
                      <Skeleton height={20}/>
                  </div>    
                </OwlCarousel> 
            </div>
            </div>
        </SkeletonTheme>
      :  <Redirect to={<NoMatch/>} />]}

      </>
     );  
  }  
}  

export default ReviewPage; 
