import React from 'react'
import axios from 'axios';
import GlobalField from '../../Classes/GlobalField';
import HomeBanner from '../../Classes/HomeBanner';
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import {apiConfig} from '../../Classes/Helper.jsx';
import SkeletonComp from '../../Classes/SkeletonComp.jsx';
import {Helmet} from "react-helmet";
import Journey from "../../Classes/Journey";

class HomePage extends React.Component {
    constructor() {
        super();
        this.state = {
          home_component : '',
          success : false
        };
      }
      organization_structured_data(home_component){
        if(home_component && home_component.site_logo){
          var site_logo = home_component.site_logo;

        }
        const script = document.createElement("script");
       
        // Structure Data script start 
        script.type = "application/ld+json";
        script.innerHTML = `{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Tri Pointe Homes",
          "url": "${window.location.href}",
          "logo": "${site_logo}",
          "sameAs": [
            "https://www.youtube.com/c/TriPointeHomes",
            "https://www.instagram.com/tripointehomes/",
            "https://www.linkedin.com/company/tri-pointe-homes/",
            "https://www.facebook.com/TriPointeHomesOfficial/"
          ]
        }`
          //  Structure Data script end 
        document.head.appendChild(script);

      }
      componentDidMount(){
        axios.get(apiConfig().API_URL+'/home_info/home')
        .then(response => {
          this.setState({
            home_component : response.data.data,
            success: response.data.success
          })
          // console.log(response);
        })
        window.CallTrk.swap();
      }
      componentDidUpdate(){
        window.CallTrk.swap();
        }
  render () {
    var success = this.state.success; 
    var message = this.state.message;
    var home_component = this.state.home_component;
    if(home_component){
      this.organization_structured_data(home_component);
      var component = home_component.component;
      for(var x in component ){
        if(component[x].acf_fc_layout == "global_faq_section")
        {component.push( component.splice(x,1)[0] )}
        
      }
    }
    var yoast_following = home_component.yoast_following;
    var yoast_indexing = home_component.yoast_indexing;
    var context_yoast = '';
    if (yoast_indexing === 'true' && yoast_following === 'true') {
      var context_yoast = 'index, follow';
    }else if (yoast_indexing === 'true' && yoast_following === 'false') {
      var context_yoast = 'index, nofollow';
    }else if (yoast_indexing === 'false' && yoast_following === 'true') {
      var context_yoast = 'noindex, follow';
    }else if (yoast_indexing === 'false' && yoast_following === 'false') {
      var context_yoast = 'noindex, nofollow';
    }
    
    return (
      <>
      <Helmet>  
              <title>{home_component.yoast_title}</title>
              <meta name="description" content={home_component.yoast_desc} />
              <meta name="robots" content={context_yoast} /> 
              <meta property="og:title" content={home_component.yoast_title ? home_component.yoast_title : ''} />
              <meta property="og:description" content={home_component.yoast_desc ? home_component.yoast_desc : ''} /> 
              <meta property="og:image" content={home_component.yoast_image}/>
              <link rel="canonical" href={window.location.href} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content='Website' />
            </Helmet> 
        {success === true ? [
            <HomeBanner banner_data={home_component.banner}/>,
            <GlobalField global_component={component} />
        ]:[message != 'Not Completed' ?  <SkeletonComp/>  :  <Redirect to={<NoMatch/>} /> ]} 
    
      </>
    )
  }
}

export default HomePage
