import React from "react";
import { srcset } from './Helpers'
import './BlogAndNewsPost.scss'
import {Link} from "react-router-dom";

class BlogAndNewsPost extends React.Component {
    render() {
        return (
            <div className="blog-and-news-post">
                <div className="image">
                    {this.props.post.image && (
                        <img className="lazy" data-srcset={srcset(this.props.post.image)}
                         alt={this.props.post.image.alt || this.props.post.image.title}/>
                    )}
                    {!this.props.post.image  && this.props.post.thumbnail.url && (
                        <img
                          src={this.props.post.thumbnail.url}
                          alt={this.props.post.thumbnail.alt || ''}
                          title={this.props.post.thumbnail.title || ''}
                        />
                    )}
                </div>
                <div>
                    {this.props.post.sub_text && (
                        <div className="sub_text">{this.props.post.sub_text}</div>
                    )}
                    {this.props.post.title && (
                        <h6 dangerouslySetInnerHTML={{__html: this.props.post.title}} />
                    )}
                    {this.props.post.excerpt && (
                        <div className="excerpt"
                             dangerouslySetInnerHTML={{__html: this.props.post.excerpt.replace(/(?:\r\n|\r|\n)/g, '')}}
                        ></div>
                    )}
                    {this.props.post.permalink && (
                        <a href={this.props.post.permalink}>Learn More</a>
                    )}
                </div>
            </div>
        );
    }
}

export default BlogAndNewsPost;
