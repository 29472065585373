import React from 'react';
import axios from 'axios';
import { apiConfig } from '../../Classes/Helper.jsx';
import Blogs from '../../Classes/Blogs.jsx';
import { Link } from 'react-router-dom'
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {Helmet} from "react-helmet";

class BlogPage extends React.Component {
  state = {
    blog_component: [],
    success: false,
    message: '',
  }

  fetch_data() {
    axios.get(apiConfig().API_URL + '/blog_info/' + this.props.match.params.slug)
      .then(response => {
        this.setState({
          blog_component: response.data.data,
          success: response.data.success,
          message: response.data.message,
        })
      })
  }
 /*Article*/
 article_structured_data(blog_component){

  const script = document.createElement("script");
  // Structure Data script start 
  script.type = "application/ld+json";
  script.innerHTML = `{
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${window.location.href}"
    },
    "headline": "${blog_component.title}",
    "image": "${blog_component.banner_image}",
    "datePublished": "${blog_component.datePublished}",
    "dateModified": "${blog_component.dateModified}",
    "author": {
      "@type": "Person",
      "name": "${blog_component.post_author}"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Tri Pointe Homes",
      "logo": {
        "@type": "ImageObject",
        "url": "${blog_component.site_logo}"
      }
    }
  }`
    //  Structure Data script end 
    document.head.appendChild(script);

}
  componentDidMount() {
    this.fetch_data();
    window.CallTrk.swap();
    window.scrollTo(0,0);
  }

  componentDidUpdate() {
    window.CallTrk.swap();
    window.scrollTo(0,0)
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname != nextProps.location.pathname) {
      this.props = nextProps;
      this.setState({
        success: false
      })
      this.fetch_data();
    }
  }
  render() {
    var success = this.state.success;
    var message = this.state.message;
    var blog_component = this.state.blog_component.blog_data;
    var toShow, textTrim;
    var window_width = window.screen.width;  
    var current_url = window.location.href.split("/");
    var slug_value = current_url[current_url.length - 2];
    if (blog_component && blog_component.page_title) {
      var page_title = blog_component.page_title;
    }
    if (blog_component && blog_component.page_desc) {
      var page_desc = blog_component.page_desc;
    }
    if (this.state.blog_component && this.state.blog_component.yoast_image) {
      var yoast_image = this.state.blog_component.yoast_image;
    }
    var yoast_following = this.state.blog_component.yoast_following;
    var yoast_indexing = this.state.blog_component.yoast_indexing;
    var context_yoast = '';
    if (yoast_indexing === 'true' && yoast_following === 'true') {
      var context_yoast = 'index, follow, max-image-preview:large'
    }else if (yoast_indexing === 'true' && yoast_following === 'false') {
      var context_yoast = 'index, nofollow, max-image-preview:large'
    }else if (yoast_indexing === 'false' && yoast_following === 'true') {
      var context_yoast = 'noindex, follow, max-image-preview:large'
    }else if (yoast_indexing === 'false' && yoast_following === 'false') {
      var context_yoast = 'noindex, nofollow, max-image-preview:large'
    }
    // const meta = {
    //   title: page_title,
    //   description: page_desc,
    //   canonical: window.location.href
    // };
      if(blog_component){
      this.article_structured_data(blog_component);
    }
    return (  
      <>
      <Helmet>  
              <title>{page_title}</title>
              <meta name="description" content={page_desc} />
              <meta property="og:title" content={page_title ? page_title : ''} />
              <meta property="og:description" content={page_desc ? page_desc : ''} />
              <meta property="og:image" content={yoast_image}/>
              <meta name="robots" content={context_yoast} />  
              <link rel="canonical" href={window.location.href} /> 
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content='Website' />
      </Helmet> 
      {
        success === true ? [
          
          <div role="main" className='blog-content-container blogs-section'>
          <div className="category_list">
            {this.state.blog_component.categories.map((item, i) =>
              <a 
              href={`/blog/category/${item.slug}/`}
              tabindex="0" 
              className="cat_list_item" key={i} 
              onKeyPress={(e)=>{
                if(e.keyCode == 13 || e.which == 13) e.target.click(); 
              }} 
              onClick={() => window.location.href = '/blog/category/' + item.slug}>{item.name.replace("&amp;", "&")}
              
              </a>
            )
            }
          </div>
          {blog_component.banner_image ? (
            <div className='blog-cover-detail-content'>
              <div className="blog-cover-content">
                {blog_component.banner_image &&
                  <div className="blog-cover-img divide-equal">
                    <img tabindex="0" src={blog_component.banner_image} title={blog_component.banner_image_title} alt={blog_component.banner_image_alt ? blog_component.banner_image_alt : blog_component.banner_image_title } />
                  </div>
                }
                <div className="blog-cover-info divide-equal">
                  <div className="header-section">
                    <div className="heading-section">
                      {blog_component.categoryname[0].name ? (

                        <p tabindex="0" className="sub-title">{blog_component.categoryname[0].name.replace("&amp;", "&")}</p>
                      ) : ('')}
                      {blog_component.title &&
                        <h1 className="main-title">
                          <span tabindex="0">{blog_component.title}</span>
                        </h1>
                      }
                    </div>
                    {(window_width > 991) ? (  
                      blog_component.summary ? (
                        <p tabindex="0" className="title-info">{blog_component.summary}</p>
                      ) : ('')
                    ) : ('')}
                    {(window_width > 991) ? (  
                    blog_component.post_author ? (
                      <div tabindex="0" className="author_name_date">By: {blog_component.post_author} {blog_component.hide_date ? '' : blog_component.post_date}</div>
                    ) : ('')
                  ):('')}
                    {(window_width > 991) ? ( 
                    <div className="social_icons">
                      <a className="facebook" href={"http://www.facebook.com/sharer.php?u=" + window.location.href + "&t=" + blog_component.title} title="Share this post on Facebook!" target="_blank" ><span class="icon-head -facebook"> </span><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                      <a className="twitter"  href={"http://twitter.com/intent/tweet?text=" + blog_component.title+ "&url="+window.location.href}  title="Share this post on Twitter!" target="_blank"><span class="icon-head -twitter"> </span><i class="fa fa-twitter" aria-hidden="true"></i></a>
                    </div>
                      ):('')}
                  </div>
                </div>
              </div>
              {blog_component.content ? (
                <div className='blog-content' >
                  <p tabindex="0" dangerouslySetInnerHTML={{ __html: blog_component.content.replace(/(?:\r\n|\r|\n)/g, '') }}></p>
                  {(window_width <= 991) ? (  
                    blog_component.post_author ? (
                      <div tabindex="0" className="author_name_date">By: {blog_component.post_author} {blog_component.hide_date ? '' : blog_component.post_date}</div>
                    ) : ('')
                  ):('')}
                  <h3 className="social_icons">
                    <a className="facebook" href={"http://www.facebook.com/sharer.php?u=" + window.location.href + "&t=" + blog_component.title} title="Share this post on Facebook!" target="_blank" ><span class="icon-head -facebook"> </span><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                    <a className="twitter"  href={"http://twitter.com/intent/tweet?text=" + blog_component.title+ "&url="+window.location.href}  title="Share this post on Twitter!" target="_blank"><span class="icon-head -twitter"> </span><i class="fa fa-twitter" aria-hidden="true"></i></a>
                  </h3>
                 
                </div>
              ) : ('')}
            </div>
          ) : ('')}

          {/* Author Detail */}
          {blog_component.author_description ?
          <div className='author_container'>
            <div className="author_content_box">
              {blog_component.author_image ? <div className="author-image"><img src={blog_component.author_image.url} alt={blog_component.author_image.alt} /></div> : ''}
              {blog_component.author_name ? 
              <div className="author_details_box">
                  <div className="author_content">
                  {blog_component.author_name ? <h4 className='author-title'>{blog_component.author_name}</h4> : ''}
                  {blog_component.author_title ? <h6>{blog_component.author_title}</h6> : ''}
                  {blog_component.author_description ?<p>{blog_component.author_description}</p> : ''}
                  <div className='author-social-icons'>
                    {blog_component.author_facebook_url ? <a href={blog_component.author_facebook_url} target='_blank'><span class="icon-head -facebook"> </span><i class="fa fa-facebook-square" aria-hidden="true"></i></a> : ''}
                    {blog_component.author_linkedIn_url ? <a href={blog_component.author_linkedIn_url} target='_blank'><span class="icon-head -linkedin"> </span><i class="fa fa-linkedin" aria-hidden="true"></i></a> : ''}
                    {blog_component.author_twitter_url ? <a href={blog_component.author_twitter_url} target='_blank'><span class="icon-head -twitter"> </span><i class="fa fa-twitter" aria-hidden="true"></i></a> : ''}
                  </div>
                  </div>
              </div>  
              : ''}
              
            </div>
          </div>  
          : ''}


          {blog_component.result.length > 0 && <div className="card_heading_content" > 
            <h4 tabindex="0" className="brand_heading">Tri Pointe Homes Blog</h4>
            <p tabindex="0" className="latest_post_heading">More to Explore</p>
          </div>}
          <div className="blog_card_container">
            {blog_component.result.map(item => (
              <div className="blog_card" >
                {item.post_thumbnail_url ? (
                  <Link to={`/blog/${item.post_name}/`}>
                    <div className="blog_card_img">
                      <img src={item.post_thumbnail_url} title={item.post_title} alt={item.post_title} />
                    </div>
                  </Link>
                ) : ('')}
                <div className="blog_card_content">
                  {item.category[0].name ? (
                    <div tabindex="0" className="blog_card_category">
                      {item.category[0].name}
                    </div>
                  ) : ('')}
                  {item.post_title ? (
                    <div tabindex="0" className="blog_card_content_caption">
                      <h3><Link to={`/blog/${item.post_name}/`}>{item.post_title}</Link></h3>
                    </div>
                  ) : ('')}
                  {item.summary ? (
                    <div className="blog_card_content_para">
                      {item.summary}
                    </div>
                  ) : ('')}
                  {item.post_name ? (
                    <div className="read_more_btn">
                      <Link to={`/blog/${item.post_name}/`} className="read_more">Read More</Link>
                    </div>
                  ) : ('')}
                </div>
              </div>
            ))}
          </div>
        </div>

      ] : [message != 'Not Completed' ? 
          <SkeletonTheme >
              <div className='blog-content-container'>
                <div className="category_list">
                  {Array(6).fill().map((item, index) => (
                    <div className="cat_list_item"> <Skeleton width={100}/></div>
                  ))}
                  </div>
                <div className="blog_listing_container">
                  <div className="blog-cover-detail-content">
                    <div className="blog-cover-content">
                      <div className="blog-cover-img divide-equal">
                          <img/>
                      </div>
                      <div className="blog-cover-info divide-equal">
                        <div className="header-section">
                          <div className="heading-section">
                            <Skeleton className="sub-title"/>
                            <h2 className="main-title">
                              <Skeleton height={50}/>
                            </h2>
                          </div>
                          <Skeleton height={100} className="title-info"/>
                          <Skeleton height={50} width={100}/>
                        </div>
                      </div>
                    </div>
                    <div className='blog-content'>
                        <Skeleton height={100}/>
                        <Skeleton count={10}/>
                        <Skeleton height={300}/>
                        <Skeleton height={100}/>
                        <Skeleton count={10}/>
                        <Skeleton height={300}/>
                        <Skeleton height={100}/>
                        <Skeleton count={10}/>
                        <Skeleton height={300}/>
                    </div>
                  </div>
                
                  <div className="">
                    <Skeleton height={30}/>
                    <Skeleton height={50}/>
                  </div>
                  <div className="blog_card_container">
                  {Array(3).fill().map((item, index) => (
                    <div className="blog_card">
                        <div className="blog_card_img">
                          <Skeleton height="100%"/>
                        </div>
                        <div className="blog_card_content">
                          <div className="blog_card_category">
                            <Skeleton />
                          </div>
                          <div className="blog_card_content_caption">
                            <Skeleton height={30}/>
                          </div>
                          <div className="blog_card_content_para">
                            <Skeleton height={100}/>
                          </div>
                          <div className="read_more_btn">
                            <Skeleton height={30} width={100}/>
                          </div>
                        </div>
                    </div>
                  ))}
                  </div>
                </div>
              </div>
            </SkeletonTheme> 
      : <Redirect to={<NoMatch />} />]
    }
        
      </>
     );
  }
}

export default BlogPage; 