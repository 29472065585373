import React from 'react';
import axios from 'axios';
import {apiConfig} from '../../Classes/Helper.jsx';
import '../../Classes/Sitemap.scss';
import { Redirect } from "react-router-dom";
import NoMatch from './NoMatch';
import SectionTitle from '../../Classes/SectionTitle';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {Helmet} from "react-helmet";

class Sitemap extends React.Component {  
 
  constructor() {
    super();
    this.state = {
      sitemap_info: [],
      success: false,
      message: '',
    }
  }

  fetch_data(){
    axios.get(apiConfig().API_URL+'/sitemap')
    .then(response => {
      this.setState({
        sitemap_info: response.data.data,
        success: response.data.success,
        message: response.data.message
      })
    })
  }
  componentDidMount(){
    this.fetch_data();

    window.CallTrk.swap();
  }

  componentDidUpdate(){
    window.CallTrk.swap();
  }
    
  render() {
    var submarket_info = this.state.sitemap_info.details;      
    var success = this.state.success; 
    var message = this.state.message; 
    var yoast_following = this.state.sitemap_info.yoast_following;
        var yoast_indexing = this.state.sitemap_info.yoast_indexing;
        var context_yoast = '';
        if (yoast_indexing === 'true' && yoast_following === 'true') {
          context_yoast = 'index, follow';
        }else if (yoast_indexing === 'true' && yoast_following === 'false') {
          context_yoast = 'index, nofollow';
        }else if (yoast_indexing === 'false' && yoast_following === 'true') {
          context_yoast = 'noindex, follow';
        }else if (yoast_indexing === 'false' && yoast_following === 'false') {
          context_yoast = 'noindex, nofollow';
        }
    // const meta = {
    //   title: this.state.sitemap_info.page_title,
    //   description: this.state.sitemap_info.page_desc,
    //   canonical: window.location.href
    // };
    if (message.length > 0 && !success ) {
      return (
        <Redirect to={<NoMatch/>} />
      )
    }

    return (
      <>
        <Helmet>  
          <title>{this.state.sitemap_info.page_title }</title>
          <meta name="description" content={this.state.sitemap_info.page_desc} />
          <meta name="robots" content={context_yoast} /> 
          <meta property="og:title" content={this.state.sitemap_info.page_title ? this.state.sitemap_info.page_title : ''} />
          <meta property="og:description" content={this.state.sitemap_info.page_desc ? this.state.sitemap_info.page_desc : ''} /> 
          <meta property="og:image" content={this.state.sitemap_info.yoast_image}/>
          <link rel="canonical" href={window.location.href} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content='Website' />
        </Helmet>
        {message == '' && (<Loading />)}
        {success === true && submarket_info != '' && (
            <div role="main" className="Sitemap_section">
              <SectionTitle headline="Sitemap" check = 'true' />
                {((Object.keys(submarket_info)).sort()).map(key => (
                  <div className="submarket_section" key={key}>
                    <h3>
                      <a href={submarket_info[key].permalink} target="_blank" rel="noopener noreferrer">{submarket_info[key].title}</a>
                    </h3>
                    {submarket_info[key]?.cmd && (
                        <div className="neigh_comm_section">
                          {Object.keys(submarket_info[key].cmd).map(sub_key => (
                              <div className="submarket_comm" key={sub_key}>
                                  <h4><a href={submarket_info[key].cmd[sub_key].permalink} target="_blank" rel="noopener noreferrer">{submarket_info[key].cmd[sub_key].post_title}</a></h4>
                                  <ul>
                                      {Object.keys(submarket_info[key].cmd[sub_key].neighborhoods).map(neigh_key => (
                                          <li key={neigh_key}>
                                            <a href={submarket_info[key].cmd[sub_key].neighborhoods[neigh_key].permalink} target="_blank" rel="noopener noreferrer">
                                              {submarket_info[key].cmd[sub_key].neighborhoods[neigh_key].post_title}
                                            </a>
                                          </li>
                                      ))}
                                  </ul>
                              </div>
                            ))}
                        </div>
                    )}
                    {submarket_info[key].nbd != null && (
                      <div className="neigh_comm_section neigh_section_2">
                        <div className="submarket_comm">
                            <h4 tabIndex="0">Neighborhoods</h4>
                            <ul>
                                {Object.keys(submarket_info[key].nbd).map(neigh_key => (
                                    <li key={neigh_key}>
                                      <a href={submarket_info[key].nbd[neigh_key].permalink} target="_blank" rel="noopener noreferrer">
                                        {submarket_info[key].nbd[neigh_key].post_title}
                                      </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
          </div>
        )}
      </>
    );  
  }  
}  


function Loading() {
  return (
    <div className="Sitemap_section">
            <SkeletonTheme >
                <Skeleton height={50} width={200}/>
                <div className="submarket_section">
                    <Skeleton height={40}/>
                    <div className="neigh_comm_section">
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={3}/></li>
                            </ul>
                        </div>   
                    </div>
                    <div className="neigh_comm_section neigh_section_2">
                      <div className="submarket_comm">
                          <Skeleton height={30}/>
                          <ul>
                            <li><Skeleton count={3}/></li>
                          </ul>
                      </div>
                    </div>
                  </div>
                <div className="submarket_section">
                    <Skeleton height={40}/>
                    <div className="neigh_comm_section">
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={3}/></li>
                            </ul>
                        </div>   
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={3}/></li>
                            </ul>
                        </div>  
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={1}/></li>
                            </ul>
                        </div>  
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={2}/></li>
                            </ul>
                        </div>  
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={2}/></li>
                            </ul>
                        </div> 
                    </div>
                    <div className="neigh_comm_section neigh_section_2">
                      <div className="submarket_comm">
                          <Skeleton height={30}/>
                          <ul>
                            <li><Skeleton count={3}/></li>
                          </ul>
                      </div>
                    </div>
                </div>
                <div className="submarket_section">
                    <Skeleton height={40}/>
                    <div className="neigh_comm_section">
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={3}/></li>
                            </ul>
                        </div>   
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={2}/></li>
                            </ul>
                        </div>  
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={3}/></li>
                            </ul>
                        </div>  
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={2}/></li>
                            </ul>
                        </div>  
                        <div className="submarket_comm">
                            <Skeleton height={30}/>
                            <ul>
                              <li><Skeleton count={3}/></li>
                            </ul>
                        </div> 
                    </div>
                    <div className="neigh_comm_section neigh_section_2">
                      <div className="submarket_comm">
                          <Skeleton height={30}/>
                          <ul>
                            <li><Skeleton count={3}/></li>
                          </ul>
                      </div>
                    </div>
                </div>
            </SkeletonTheme>
          </div>
  )
}

export default Sitemap; 