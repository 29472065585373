import React from 'react';
import axios from 'axios';
import CommonNeighCard from '../../Classes/CommonNeighCard';
import GlobalField from '../../Classes/GlobalField';
import GeoAssociates from '../../Classes/GeoAssociates'
import NoMatch from './NoMatch';
import { Redirect } from "react-router-dom";
import {apiConfig} from '../../Classes/Helper.jsx';
// import FaqSection from '../../Classes/FaqSection';
import SectionTitle from '../../Classes/SectionTitle';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {Helmet} from "react-helmet";


class SubmarketPage extends React.Component {
    constructor() {
      super();
    }
    state ={
       submarket_component : [],
        success : false,
      }
      componentDidMount(){
        axios.get(apiConfig().API_URL+'/sub_city_info/'+ this.props.match.params.slug)
        .then(response => {
          this.setState({
            submarket_component : response.data.data,
            success: response.data.success,
            message:response.data.message
          })
          // console.log(response);
        })

        window.CallTrk.swap();
      }

      componentDidUpdate(){
        window.CallTrk.swap();
      }

  render(){

    var success = this.state.success;
    var message = this.state.message;
    var submarket_component = this.state.submarket_component;
    if(submarket_component && submarket_component.site_logo){
      var gc_count=submarket_component.global_components;
      var component = submarket_component.component;
      for(var x in component ){
        if(component[x].acf_fc_layout == "global_faq_section")
         {component.push( component.splice(x,1)[0] )}

        }
    }
    var yoast_following = submarket_component.yoast_following;
    var yoast_indexing = submarket_component.yoast_indexing;
    var context_yoast = '';
    if (yoast_indexing === 'true' && yoast_following === 'true') {
    var context_yoast = 'index, follow';
    }else if (yoast_indexing === 'true' && yoast_following === 'false') {
    var context_yoast = 'index, nofollow';
    }else if (yoast_indexing === 'false' && yoast_following === 'true') {
    var context_yoast = 'noindex, follow';
    }else if (yoast_indexing === 'false' && yoast_following === 'false') {
    var context_yoast = 'noindex, nofollow';
    }
    return(
      <> 
         <Helmet> 
              <title>{submarket_component.yoast_title}</title>
              <meta name="description" content={submarket_component.yoast_desc} /> 
              <meta name="robots" content={context_yoast} />  
              <meta property="og:title" content={submarket_component.yoast_title ? submarket_component.yoast_title : ''} />
              <meta property="og:description" content={submarket_component.yoast_desc ? submarket_component.yoast_desc : ''} />
              <meta property="og:image" content={submarket_component.yoast_image}/>
              <link rel="canonical" href={window.location.href} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content='Website' />
            </Helmet> 
         <div role="main" className="cards-section">
        {success === true ? [
            submarket_component.state_details.state_abb && this.props.match.params.statename === submarket_component.state_details.state_abb.toLowerCase() ? [
              submarket_component.community.length > 0 || submarket_component.neighborhood.length > 0 ||    submarket_component.global_components > 0 ? [
                gc_count > 0 ? [
                  <GlobalField global_component={submarket_component.component} slice_start={0} slice_end={submarket_component.cards_position}/>,
                ]:[''],
                
                  submarket_component.community.length > 0 ? [
                    <SectionTitle headline={submarket_component.canonical_pages_data.community_headline} subtext={submarket_component.canonical_pages_data.community_subtext+' ('+ submarket_component.community_count+ ')'} posttype="submarket" community="community"/>,
                    <CommonNeighCard card_detail={submarket_component.community} posttype="community" state_abb = {submarket_component.state_details} show_detail='true'/>
                  ]:[''],

                  submarket_component.neighborhood.length > 0 ? [
                    <SectionTitle headline={submarket_component.canonical_pages_data.neighborhood_headline} subtext={submarket_component.canonical_pages_data.neighborhood_subtext+' ('+ submarket_component.neighborhood_count+ ')'} posttype="submarket" />,
                    <CommonNeighCard card_detail={submarket_component.neighborhood} posttype="neighborhood" state_abb = {submarket_component.state_details} show_detail='true'/>
                  ]:[''],
                  gc_count >=submarket_component.cards_position ? (
                    <GlobalField global_component={submarket_component.component} slice_start={submarket_component.cards_position}/>):(''),
                  <GeoAssociates associated_data={submarket_component.associated_cities} component={submarket_component} title='Where We Build In' />
                 
                
                // <FaqSection community_details={submarket_component}/>
              ]:[ <Redirect to={<NoMatch/>} />  ]
            ]:[ <Redirect to={<NoMatch/>} />  ]
        ]:[ message != 'Not Completed' ?
          <SkeletonTheme >
              <div className="plan-banner">
                <div className="HeroContent d-flex">
                    <div className="HeroContentWrapper">
                        <div className="bannerHeader">
                            <div className="heading-section">
                                <Skeleton height={40}/>
                                <Skeleton />
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="video-main-div">
                <a href="javascript:void(0)" className="video-iframe divide-equal">
                  <Skeleton height={"100%"}/>
                </a>
                <div className="video-info divide-equal">
                  <div className="heading-section">
                    <Skeleton height={20} />
                    <Skeleton height={40} />
                  </div>
                  <Skeleton height={200} className="title-info"/>
                </div>
              </div>
              <div className="sectiontitle-main-div">
                <div className="sectiontitle-headline gray-color">
                  <Skeleton height={40}/>
                </div>
                <div className="eyebrow-text-div">
                  <Skeleton height={30} width={200} className="sectiontitle-eyebrow_text"/>
                  <div className="eyebrowtext-border"></div>
                </div>
              </div>
              <div className="common-card">
                <div className="card-main-div">
                  <div className="card-imgs">
                    <Skeleton height={"100%"} />
                    <div className="plan-img-info">
                      <div className="card-img-info">
                        <Skeleton height={20} className="card-status"/>
                        <Skeleton height={40} className="card-name"/>
                        <Skeleton height={20} className="card-price"/>
                      </div>
                    </div>
                  </div>
                  <div className="card-info">
                    <ul className="card-detail">
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-main-div">
                  <div className="card-imgs">
                    <Skeleton height={"100%"} />
                    <div className="plan-img-info">
                      <div className="card-img-info">
                        <Skeleton height={20} className="card-status"/>
                        <Skeleton height={40} className="card-name"/>
                        <Skeleton height={20} className="card-price"/>
                      </div>
                    </div>
                  </div>
                  <div className="card-info">
                    <ul className="card-detail">
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                      <li>
                        <Skeleton width={100}/>
                        <Skeleton width={100}/>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </SkeletonTheme>
        :  <Redirect to={<NoMatch/>} />]}
        </div>
      </>
    );
  }
}

export default SubmarketPage;